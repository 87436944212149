import Keycloak from 'keycloak-js';
const iam = new Keycloak({
 url: 'https://kapp.cyberresult.com',
 realm: 'mohre-realm',
 clientId: 'react-client',
});
/*const iam = new Keycloak({
 url: 'https://kapp.cyberresult.com',
 realm: 'mohre-uae-pass-realm',
 clientId: 'react-uae-pass-client',
});*/
export default iam;
