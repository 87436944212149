import React from 'react';
import ReactDOM from 'react-dom';
import styles from "./index.css";
import App from './App';
import iam from './iam';
iam.init({ onLoad: 'login-required' }).then(authenticated => {
 if (authenticated) {
   ReactDOM.render(<App />, document.getElementById('root'));
 } else {
   window.location.reload();
 }
}).catch(console.error);
